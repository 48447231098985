import React from "react"
import { Row, Col, Form, Input, Button } from "antd"
import { KeyOutlined } from '@ant-design/icons';
import { CHANGE_PASSWORD, requestWebService } from "../utils/webservices";
import * as queryString from "query-string";


export const FormPassword = ({ nome, location }) => {
    const [form] = Form.useForm();
    const [state, setState] = React.useState({
        loadingBtn: false,
        successForm: false,
        errorForm: false
    });



    const onFinish = async (values) => {
        var query = queryString.parse(location.search)
        var tkn = query.tkn.split(" ").join("+");
        setState({
            ...state,
            successForm: false,
            errorForm: false,
            loadingBtn: true
        });
        var res = await requestWebService(CHANGE_PASSWORD, { tkn: tkn, password: values.password, confirm_password: values.confirm_password });
        if (res && res.status === 200) {
            setState({
                ...state,
                loadingBtn: false,
                successForm: true,
                errorForm: false
            })
        } else {
            setState({
                ...state,
                loadingBtn: false,
                successForm: false,
                errorForm: true
            })
        }
    }


    return <>
        <Row align="middle" justify="center" type="flex">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                <h1>MyPuratos</h1>
                <h2>Ciao {nome}</h2>
                {!state.successForm && <h2>Inserisci la nuova password</h2>}
                {state.successForm &&
                    <>
                        <h2 style={{ color: 'green' }}>La tua password è stata cambiata con successo.</h2>
                        <h2 style={{ color: 'green' }}>Apri l'app MyPuratos e inserisci le nuove credenziali.</h2>
                        {/* <Button type="primary" href="/" title="Home page">Home page</Button> */}
                    </>
                }
            </Col>
        </Row>
        {state.errorForm && <Row align="middle" justify="center" type="flex">
            <Col xs={24} sm={16} md={12} lg={8} xl={8} style={{ textAlign: 'center' }}>

            </Col>
        </Row>}
        {!state.successForm && <Row align="middle" justify="center" type="flex">
            {state.errorForm && <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center' }}>
                <h3 style={{ color: '#ff0000' }}>Errore impossibile salvare i dati.</h3>
            </Col>}
            <Col xs={24} sm={16} md={12} lg={8} xl={8} style={{ textAlign: 'center' }}>
                <Form
                    form={form}
                    layout={'vertical'}
                    onFinish={onFinish}>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            { required: true, message: 'Password campo obbligatorio' },
                            { min: 6, message: 'La password deve contenere almeno 6 caratteri.' }
                        ]}
                    >
                        <Input.Password
                            prefix={
                                <KeyOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                            }
                            placeholder="Password" />

                    </Form.Item>
                    <Form.Item
                        label="Conferma Password"
                        name="confirm_password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            { required: true, message: 'Conferma password campo obbligatorio.' },
                            { min: 6, message: 'La password deve contenere almeno 6 caratteri.' },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Le password non corrispondono.');
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={
                                <KeyOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                            }
                            placeholder="Conferma Password" />

                    </Form.Item>
                    <Form.Item style={{ marginTop: 10, marginBottom: 10 }}>
                        <Button htmlType="submit" type="primary" loading={state.loadingBtn}  style={{background: '#eab300', color: '#ffffff', borderColor: '#eab300' }}>Cambia password</Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>}
    </>
};
