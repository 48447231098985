import axios from 'axios';
axios.defaults.baseURL = "https://api.mypuratos.it/api/site";
//axios.defaults.baseURL = "http://localhost:5003/api/site";
axios.defaults.timeout = 60 * 1000;
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';

export const CHECK_TOKEN_PASS_USER = "/check-user-tkn";
export const CHANGE_PASSWORD = "/change-password";

export function requestWebService(link, dati) {
    return axios
        .post(link, dati)
        .then(function (response) {
            return successHTTP(response.data);
        })
        .catch(function (error) {
            return errorHTTP(error);
        });
}

const successHTTP = (res) => {
    return res;
};

const errorHTTP = (err) => {
    if (err == 'timeout of 10000ms exceeded') {
        return { error: true, message: 'timeout' };
    }
    return { error: true, ...err.response };
};
