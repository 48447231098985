import React from "react"
import { Row, Col, Spin } from 'antd';
import LayoutApp from "../components/layout"
import SEO from "../components/seo"
import { CHECK_TOKEN_PASS_USER, requestWebService } from "../utils/webservices";
import * as queryString from "query-string";
import { FormPassword } from "../components/FormPassword";

var sendReq = false;

const SecondPage = ({ location }) => {
  const [state, setState] = React.useState({
    nome: null,
    loading: true,
    error: false
  });
  React.useEffect(() => {
    var query = queryString.parse(location.search)
    async function checkUser() {
      if (query && query.tkn) {
        var tkn = query.tkn.split(" ").join("+");
        var res = await requestWebService(CHECK_TOKEN_PASS_USER, { tkn: tkn });
        if (res && res.status === 200) {
          setState({
            ...state,
            loading: false,
            error: false,
            nome: res.user
          });
        } else if (res && res.status === 201) {
          window.location = '/';
        } else {
          if (!sendReq) {
            sendReq = true;
            checkUser();
          } else {
            setState({
              ...state,
              loading: false,
              error: true
            })
          }
        }
      } else {
        setState({
          ...state,
          loading: false,
          error: true
        })
      }
    }
    setTimeout(() => {
      checkUser();
    }, 2000);
    return () => {
      sendReq = false;
    };
  }, []);


  return <LayoutApp>
    <SEO title="Cambia password" />
    <Row align="middle" justify="center" type="flex">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center', padding: 10 }}>
        <img alt="Logo Puratos Italia" src="/images/logo.png" width="200px" />
      </Col>
    </Row>
    <Row align="middle" justify="center" type="flex">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'center', padding: 10 }}>
        {state.loading && <div style={{
          minHeight: 400,
          textAlign: 'center',
          marginBottom: 20,
          padding: '30px 50px',
          margin: '20px 0',
        }}>
          <Spin spinning={state.loading} />
        </div>}
        {state.error && <div style={{
          minHeight: 400,
          textAlign: 'center',
          marginBottom: 20,
          padding: '30px 50px',
          margin: '20px 0',
        }}>
          <h1>Errore: impossibile connettersi al server.</h1>
          <h3>Se il problema persiste contattaci: lcafasso@puratos.com</h3>
        </div>}
        <div style={{ minHeight: 400 }}>
          {state.nome && !state.loading && !state.error && <FormPassword location={location} nome={state.nome} />}
        </div>
      </Col>
    </Row>

  </LayoutApp>

}

export default SecondPage
